import React from 'react'
import PropTypes from 'prop-types'
import { useRefresh } from '../../hooks/useRefresh'
import {
  MAX_REFRESHES,
  REFRESH_INTERVAL,
  REMOUNT_INTERVAL,
} from '../../data/constants'

const adFormats = {
  fixed: {
    styles: {
      display: 'inline-block',
      width: '728px',
      height: '90px',
    },
    attr: {},
  },
  responsive: {
    styles: {
      display: 'block',
    },
    attr: {
      'data-ad-format': 'auto',
      'data-full-width-responsive': 'true',
    },
  },
}
export default function Adsense({ slot, format = 'responsive' }) {
  const { isRefreshed } = useRefresh({
    maxRefreshes: MAX_REFRESHES,
    refreshInterval: REFRESH_INTERVAL,
    remountInterval: REMOUNT_INTERVAL,
  })
  React.useEffect(() => {
    try {
      if (isRefreshed) {
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
      }
    } catch (err) {
      console.log(err)
    }
  }, [isRefreshed])

  return (
    <>
      {!isRefreshed ? (
        <div>Loading...</div>
      ) : (
        <div className="adsense-wrapper">
          <ins
            className="adsbygoogle"
            style={adFormats[format].styles}
            data-ad-client="ca-pub-2424675983492321"
            data-ad-slot={slot}
            {...adFormats[format].attr}
          ></ins>
        </div>
      )}
    </>
  )
}

Adsense.propTypes = {
  slot: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
}
