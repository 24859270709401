export const SEOMetaData = {
  home: {
    title: 'Free Premium Link Generator Online | Cboxera.com',
    path: '/',
    keywords:
      'free premium link generator, free link generator, prefiles premium link generator, turbobit premium link generator, nitroflare leech, uploaded leech, rapidgator leech, wushare link generator, wdupload leech, rapidgator link generator, uploadgig leech, uploadgig link generator, syncs.online link generator, premium link generator 2022, premium link generator 2023',
    description:
      'Download files from various file hosting services with no restrictions using Cboxera.com. Get high-speed and safe downloads now.',
  },
  register: {
    title: 'Register | Cboxera.com',
    path: '/register',
    keywords:
      'cboxera register, cboxera signup, cboxera premium account, cboxera vip account, cboxera free account, create cboxera account',
    description:
      'Create a free cboxera account and generate premium links for free.',
  },
  login: {
    title: 'Login | Cboxera.com',
    path: '/login',
    keywords:
      'cboxera.com login, cboxera login, cboxera premium login, cboxera free login, cboxera account login',
    description: 'Login with your cboxera.com account',
  },
  premium: {
    title: 'Premium | Cboxera.com',
    path: '/premium',
    keywords:
      'cboxera premium plans, premium account, pricing, premium plans, vip account, cboxera pricing, cboxera payments',
    description:
      'Choose the best suited plan and upgrade your cboxera account to Premium and enjoy premium host downloads at high speed without any limits',
  },
  hostStatus: {
    title: 'Supported FileHosts | Cboxera.com',
    path: '/host-status',
    keywords:
      'cboxera host status, cboxera limits, cboxera free user limits, cboxera filesize limits, cboxera bandwidth limits, cboxera supported hosts, cboxera free hosts, cboxera premium hosts, cboxera supported filehosts, cboxera supported filehost list, cboxera filehost updates',
    description: 'Track realtime supported host updates at cboxera.com',
  },
  faqs: {
    title: 'Frequently Asked Questions | Cboxera.com',
    path: '/faqs',
    keywords:
      'cboxera faqs, cboxera faq, cboxera.com faqs, cboxera.com, faq, frequently asked questions at cboxera.com',
    description: 'Frequently asked questions at cboxera.com',
  },
  about: {
    title: 'About us! | Cboxera.com',
    path: '/about',
    keywords:
      'cboxera about, how cboxera is built, what cboxera does, what is cboxera?',
    description: 'All about cboxera.com information',
  },
  forgotPassword: {
    title: 'Forgot Password | Cboxera.com',
    path: '/forgot-password',
    keywords:
      'Forgot password, cboxera.com forgot password, cboxera password reset, reset password at cboxera',
    description: 'Reset your lost password at cboxera.com',
  },
  contact: {
    title: 'Support | Cboxera.com',
    path: '/contact',
    keywords:
      'cboxera support, contact us, support team at cboxera.com, contact support',
    description: 'Contact our support for any help!',
  },
  terms: {
    title: 'Terms of service | Cboxera.com',
    path: '/terms',
    keywords:
      'cboxera terms and conditions, cboxera tos, cboxera terms, cboxera rules',
    description: 'Terms of service',
  },
  downloader: {
    title: 'Downloader | Cboxera.com',
    path: '/user/downloader',
    keywords:
      'premium links generator, downloader, multi link downloader, high speed downloads, links generator',
    description:
      'Generate download links from more than 40+ filehosts for free!',
  },
  profile: {
    title: 'Profile | Cboxera.com',
    path: '/user/profile',
    keywords:
      'my profile, cboxera account profile, user profile, Change password, saved links, settings',
    description: 'Manage your account at cboxera easily',
  },
  verifyEmail: {
    title: 'Verification | Cboxera.com',
    path: '/user/verify',
    keywords: 'verify email, cboxera account Verification',
    description: 'Email verification cboxera.com',
  },
  resetPassword: {
    title: 'Reset Password | Cboxera.com',
    path: '/user/reset-password',
    keywords:
      'cboxera reset password, password reset, forgot password, update password',
    description: 'Update/Reset password for cboxera account',
  },
  download: {
    title: 'Download File | Cboxera.com',
    path: '/user/download',
    keywords:
      'free premium link generator, free link generator, prefiles premium link generator, turbobit premium link generator, nitroflare leech, uploaded leech, rapidgator leech, wushare link generator, wdupload leech, rapidgator link generator, uploadgig leech, uploadgig link generator, syncs.online link generator, premium link generator 2022, premium link generator 2023',
    description:
      'Download files from various file hosting services with no restrictions using Cboxera.com. Get high-speed and safe downloads now.',
  },
  paymentStatus: {
    title: 'Payment Status | Cboxera.com',
    path: '/user/download',
    keywords: 'premium status, payment status, cboxera payment status',
    description: 'Free Download Premium links at cboxera.com',
  },
  oops404: {
    title: '404 Not Found',
    path: '/',
    keywords:
      'free premium link generator, free link generator, prefiles premium link generator, turbobit premium link generator, nitroflare leech, uploaded leech, rapidgator leech, wushare link generator, wdupload leech, rapidgator link generator, uploadgig leech, uploadgig link generator, syncs.online link generator, premium link generator 2022, premium link generator 2023',
    description:
      'Download files from various file hosting services with no restrictions using Cboxera.com. Get high-speed and safe downloads now.',
  },
  default: {
    title: 'Free Premium Link Generator Online | Cboxera.com',
    path: '/',
    keywords:
      'free premium link generator, free link generator, prefiles premium link generator, turbobit premium link generator, nitroflare leech, uploaded leech, rapidgator leech, wushare link generator, wdupload leech, rapidgator link generator, uploadgig leech, uploadgig link generator, syncs.online link generator, premium link generator 2022, premium link generator 2023',
    description:
      'Download files from various file hosting services with no restrictions using Cboxera.com. Get high-speed and safe downloads now.',
  },
}
