import { useState, useEffect } from 'react'

export const useRefresh = ({
  refreshInterval = 10 * 60 * 1000, // 10 min
  remountInterval = 3000, // 3 sec
  maxRefreshes = 10,
}) => {
  const [isRefreshed, setIsRefresh] = useState(true)
  const [refreshCount, setRefreshCount] = useState(0)

  useEffect(() => {
    let interval
    if (refreshCount === maxRefreshes) {
      clearInterval(interval)
      return
    }
    if (isRefreshed) {
      console.log(refreshCount, maxRefreshes)
      interval = setInterval(() => {
        setIsRefresh(false)
        setRefreshCount(refreshCount + 1)
      }, refreshInterval)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isRefreshed])

  useEffect(() => {
    let timeout
    if (!isRefreshed) {
      timeout = setTimeout(() => {
        setIsRefresh(true)
      }, remountInterval)
    }
    return () => {
      if (timeout) {
        clearInterval(timeout)
      }
    }
  }, [isRefreshed])
  return {
    isRefreshed,
    refreshCount,
  }
}
